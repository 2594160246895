import { CloudDownloadOutlined, RollbackOutlined, SaveOutlined, UnlockOutlined } from "@ant-design/icons";
import { Button, Col, Form, notification, Row, Space } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";
import CancelledPartnerGeneralPane from "./CancelledPartnerGeneralPane";
import ContractorGeneralPane from "./ContractorGeneralPane";
import PartnerCancelModal from "./PartnerCancelModal";
import WorkerGeneralPane from "./WorkerGeneralPane";

import { PartnerContext } from "../../pages/PartnerProfile";

const PartnerGeneralPane = () => {
  const { t } = useTranslation();

  const [partnerForm] = Form.useForm();

  const partnerContext = useContext(PartnerContext);

  const { partner, refreshPartnerState, partnerFormReadOnly, refreshPartnerFormReadOnly } = partnerContext;

  const [isFormSending, setFormSending] = useState(false);
  const [isExternalSourceFetching, setExternalSourceFetching] = useState(false);
  const [isContractorView, setContractorView] = useState(false);

  useEffect(() => {
    refreshPartnerFormReadOnly(true);
    partnerForm.resetFields();

    setContractorView(Boolean(partner?.company_number));
  }, [partner, partnerForm]);

  return (
    <React.Fragment>
      <Form
        form={partnerForm}
        onFinish={(values) => {
          setFormSending(true);

          values.contract_signed = values.contract_signed?.format("YYYY-MM-DD");
          values.birth_date = values.birth_date?.format("YYYY-MM-DD");

          let url = "/partners/" + (isContractorView ? "contractors/" : "workers/") + partner?.id;

          //Moment js idiocy
          values.insurance_expiration_date = values.insurance_expiration_date
            ? values.insurance_expiration_date.format("YYYY-MM-DD")
            : null;
          values.training_day_date = values.training_day_date ? values.training_day_date.format("YYYY-MM-DD") : null;

          coreApi
            .put(url, values)
            .then((response) => {
              refreshPartnerState({ ...partner, ...(response.data.contractor || response.data.worker) });

              notification.success({
                message: response.data.message,
              });
            })
            .catch((err) => {
              let errors = "";

              for (const key in err.response.data.errors) {
                errors += err.response.data.errors[key][0] + " ";
              }

              notification.error({ message: errors });
            })
            .finally(() => {
              setFormSending(false);
              refreshPartnerFormReadOnly(true);
            });
        }}
      >
        <Row gutter={[24, 24]}>
          <Col span={24}>
            {partnerFormReadOnly ? (
              <Button
                icon={<UnlockOutlined />}
                onClick={() => {
                  refreshPartnerFormReadOnly(false);
                }}
                style={{ float: "right" }}
              >
                {t("common.allowChanges")}
              </Button>
            ) : (
              <Space style={{ float: "right" }}>
                {partner?.contract_ended ? "" : <PartnerCancelModal partner={partner} />}

                {/* {isContractorView ? ( */}
                <Button
                  loading={isExternalSourceFetching}
                  icon={<CloudDownloadOutlined />}
                  onClick={() => {
                    setExternalSourceFetching(true);
                    coreApi
                      .get("/partners/get-public-data/" + partnerForm.getFieldValue("company_number"))
                      .then((response) => {
                        partnerForm.setFieldsValue({
                          company_name: response.data.company_name,
                          billing_address_city: response.data.city,
                          billing_address_street: response.data.street,
                          billing_address_postal_code: response.data.postal_code,
                          billing_address_house_number: response.data.house_number,
                        });
                      })
                      .catch((error) => {})
                      .finally(() => {
                        setExternalSourceFetching(false);
                      });
                  }}
                >
                  {t("partners.loadExternalData")}
                </Button>
                {/* ) : (
                  <Button
                    onClick={() => {
                      setContractorView(true);
                      notification.info({
                        message: t("common.warning"),
                        description: t("partners.contractorConversionWarningMessage"),
                        duration: null,
                      });
                    }}
                  >
                    {t("partners.convertToContractor")}
                  </Button>
                )} */}
                <Button
                  icon={<RollbackOutlined />}
                  onClick={() => {
                    partnerForm.resetFields();
                    refreshPartnerFormReadOnly(true);
                  }}
                >
                  {t("common.discardChanges")}
                </Button>
                <Button
                  loading={isFormSending}
                  icon={<SaveOutlined />}
                  onClick={() => {
                    partnerForm.submit();
                  }}
                >
                  {t("common.saveChanges")}
                </Button>
              </Space>
            )}
          </Col>
          <Col span={24}>
            {partner?.contract_ended ? (
              <CancelledPartnerGeneralPane partner={partner} readOnly={partnerFormReadOnly} />
            ) : (
              <React.Fragment>
                {isContractorView ? (
                  <ContractorGeneralPane partner={partner} readOnly={partnerFormReadOnly} />
                ) : (
                  <WorkerGeneralPane partner={partner} readOnly={partnerFormReadOnly} />
                )}
              </React.Fragment>
            )}
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
};

export default PartnerGeneralPane;
