import React, { useState, useEffect, useRef } from "react";
import { Form, Input, Button, Select, DatePicker, notification, Row, Col } from 'antd';
import CitiesSelector from '../../Cities/CitiesSelector';
import BuildingSelect from './../BuildingSelect';
import BuildingUnitSelect from './../BuildingUnitSelect';
import PartnerSelect from '../../Partners/PartnerSelect';
import ManagerSelect from '../../ManagerSelect';
import { coreApi } from "../../../api/calls";
import { useTranslation } from "react-i18next";

const { Option } = Select;
const { TextArea } = Input;

const AddForm = ({ onFormSubmit, onCancel }) => {
  const { t, i18n } = useTranslation();
  const user = useRef({ ...JSON.parse(localStorage.getItem("user")) }).current;
  const [form] = Form.useForm();

  const [statuses, setStatuses] = useState([{"id": 1,"name": t("buildingSetupChanges.statusInSolution") }]);
  const [actions, setActions] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedBuilding, setSelectedBuilding] = useState(null);
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [selectedCleaningDay, setSelectedCleaningDay] = useState(null);
  const days = ["MO", "TU", "WE", "TH", "FR", "SA", "SU"];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const [statusesRes, actionsRes] = await Promise.all([
        coreApi.get("/building-unit-setup-changes/actions/statuses"),
        coreApi.get("/building-unit-setup-changes/actions/list-actions"),
      ]);

      setStatuses(statusesRes.data);
      setActions(actionsRes.data);
    } catch (error) {
      notification.error({ message: t("buildingSetupChanges.messages.failedLoadData") });
    }
  };

  const handleCityChange = (value, option) => {
    const countryBranchId = option?.['data-country-branch-id'];
    form.setFieldsValue({ city_branch_id: value, country_branch_id: countryBranchId, building_id: null, partner_id: null, building_unit_ids: [] });
    setSelectedCity(value);
    setSelectedBuilding(null);
    setSelectedPartner(null);
  };

  const handleBuildingChange = async (obj) => {
    const newBuildingId = obj?.id;
    form.setFieldsValue({ building_unit_ids: [], building_id: newBuildingId });
    setSelectedBuilding(newBuildingId);
    // if (newBuildingId) {
    //   try {
    //     const response = await coreApi.get(`/buildings/active-partners/${newBuildingId}`);
    //     const partnerList = response.data;
    //
    //     if (partnerList.length > 0) {
    //       const firstPartnerId = partnerList[0].id;
    //       form.setFieldsValue({ partner_id: firstPartnerId });
    //       setSelectedPartner(firstPartnerId);
    //     }
    //     else {
    //       form.setFieldsValue({ partner_id: null });
    //       setSelectedPartner(null);
    //     }
    //   } catch (error) {
    //     notification.error({ message: t("buildingSetupChanges.messages.failedLoadData") });
    //   }
    // }
  };

  const handlePartnerChange = (obj) => {
    const newPartnerId = obj?.id;

    if (newPartnerId !== selectedPartner && selectedBuilding !== null) {
      form.setFieldsValue({ partner_id: newPartnerId });
      setSelectedPartner(newPartnerId);
      return;
    }

    form.setFieldsValue({ building_unit_ids: [], partner_id: newPartnerId });
    setSelectedPartner(newPartnerId);
  };


  const handleSubmit = async (values) => {
    if (!values.building_unit_ids || values.building_unit_ids.length === 0) {
      notification.error({ message: t("buildingSetupChanges.validation.requiredBuildingUnit") });
      return;
    }

    const { period, ...restValues } = values;
    const action_week_from = period ? period[0] : null;
    const action_week_to = period ? period[1] : null;

    const payload = {
      ...restValues,
      action_week_from,
      action_week_to,
    };

    coreApi
      .post("/building-unit-setup-changes", payload)
      .then(() => {
        notification.success({ message: t("buildingSetupChanges.messages.addedSuccessfully") });
        setSelectedCity(null);
        setSelectedBuilding(null);
        setSelectedPartner(null);
        onFormSubmit();
        form.resetFields();
      })
      .catch((error) => {
        notification.error({ message: t("buildingSetupChanges.messages.failedAdd") });
      });
  };

  useEffect(() => {
    return () => {
      form.resetFields();
      setSelectedCity(null);
      setSelectedBuilding(null);
      setSelectedPartner(null);
    };
  }, [form]);

  // const handleRruleChange = ( newRrule) => {
  //   form.setFields([
  //     {
  //       name: ['items', 'substitute_rrule'],
  //       value: newRrule,
  //     },
  //   ]);
  // };
  const handleRruleChange = (newRrule) => {
    setSelectedCleaningDay(newRrule);
  };


  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      initialValues={{ status_id: 1 }}
    >
      <CitiesSelector
        onChange={handleCityChange}
        mode="single"
      />
      <Form.Item
        name="city_branch_id"
        hidden={true}
      >
        <Input type="hidden" />
      </Form.Item>
      <Form.Item
        name="country_branch_id"
        hidden={true}
      >
        <Input type="hidden" />
      </Form.Item>

      <Row gutter={16}>
        <Col span={12}>
          <BuildingSelect
            label={t("buildingSetupChanges.building")}
            name="building_id"
            validationMessage={t("buildingSetupChanges.validation.requiredBuilding")}
            onChange={handleBuildingChange}
            cityBranchId={selectedCity}
          />
        </Col>
        <Col span={12}>
          <PartnerSelect
            label={t("buildingSetupChanges.partners")}
            name="partner_id"
            onChange={handlePartnerChange}
          />
        </Col>
      </Row>
      {(!selectedCity && !selectedBuilding && selectedPartner) && (
      <Row gutter={16}>
        <Col span={12}></Col>
        <Col span={12}>
          <Form.Item>
            <Select
              allowClear
              placeholder={t("rrules.weekday")}
              onChange={(values) => handleRruleChange(values)}
            >
              {days.map((day) => (
                <Option key={day} value={day}>
                  {t("rrules.byweekday." + day)}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      )}

      {(selectedCity || selectedBuilding || selectedPartner) ? (
        <BuildingUnitSelect
          label={t("buildingSetupChanges.buildingUnit")}
          name="building_unit_ids"
          required
          validationMessage={t("buildingSetupChanges.validation.requiredBuildingUnit")}
          buildingId={selectedBuilding}
          partnerId={selectedPartner}
          cityBranchId={selectedCity}
          cleaningDay={selectedCleaningDay}
          selectAllOnLoad={true}
          mode="multiple"
          form={form}
        />
      ) : (
        <Form.Item
          label={t("buildingSetupChanges.buildingUnit")}
          required
        >
          <Select disabled placeholder={t("buildingSetupChanges.selectBuildingUnit")} />
        </Form.Item>
      )}

      <Form.Item
        name="status_id"
        label={t("buildingSetupChanges.status")}
        rules={[{ required: true, message: t("buildingSetupChanges.validation.requiredStatus") }]}
      >
        <Select placeholder={t("buildingSetupChanges.selectStatus")}>
          {statuses.map(status => (
            <Option key={status.id} value={status.id}>{status.name}</Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="action_id"
        label={t("buildingSetupChanges.action")}
        rules={[{ required: true, message: t("buildingSetupChanges.validation.requiredAction") }]}
      >
        <Select placeholder={t("buildingSetupChanges.selectAction")}>
          {actions.map(action => {
            const translation = action.translations.find(t => t.language_ietf === i18n.language) || action.translations[0];
            return (
              <Option key={action.id} value={action.id}>
                {translation?.tag_name}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
      <ManagerSelect
        label={t("buildingSetupChanges.manager")}
        validationMessage={t("buildingSetupChanges.validation.requiredManager")}
        required
        readOnly={false}
        showSearch={true}
        initialValue={user.employee_id}
      />
        <Form.Item name="notes">
          <TextArea
            rows={4}
            placeholder={t("common.notes")}
            maxLength={250}
            showCount
          />
        </Form.Item>
      <Form.Item
        label={t("common.selectedPeriod")}
        name="period"
        rules={[{ required: true, message: t("buildingSetupChanges.validation.requiredPeriod") }]}
      >
        <DatePicker.RangePicker
          placeholder={[t("buildingSetupChanges.selectStartDate"), t("buildingSetupChanges.selectEndDate")]}
          allowEmpty={[false, true]}
        />
      </Form.Item>
      <Form.Item style={{ textAlign: "right" }}>
          <Button type="ghost" onClick={onCancel} style={{ marginRight: 8 }}>
            {t("common.cancel")}
          </Button>
          <Button type="primary" htmlType="submit">
            {t("common.save")}
          </Button>
      </Form.Item>
    </Form>
  );
};

export default AddForm;
