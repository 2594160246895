import { DownOutlined, RightOutlined } from "@ant-design/icons";
import { Col, Row, notification, Table, Tag, Button, Modal } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { coreApi } from "../../api/calls";
import { INVOICE_TYPES } from "../../constants";

const InvoicePane = ({ client }) => {
  const { t } = useTranslation();

  // States
  const [dataLoading, setDataLoading] = useState(true);
  const [pagination, setPagination] = useState({});
  const [invoices, setInvoices] = useState([]);
  const [filters, setFilters] = useState({
    page: 1,
    page_size: 10,
  });
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const onSelectChange = (selectedRowKeys, selectedRows) => {
    const filteredInvoices = selectedInvoices.filter(inv => inv.corrected_invoice_id);
    const combinedSelections = [...filteredInvoices, ...selectedRows.filter(row => !row.corrected_invoice_id)];
    setSelectedInvoices(combinedSelections);
  };

  const rowSelection = {
    selectedRowKeys: selectedInvoices.map(invoice => invoice.id),
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: ![
        INVOICE_TYPES.PARTNER_CLIENT,
        INVOICE_TYPES.CORRECTIVE_DOCUMENT
      ].includes(record.invoice_type),
      name: `invoice-${record.id}`,
    }),
  };

  const onNestedSelectChange = (selectedRowKeys, selectedRows, parentRow) => {
    // Remove selections related to this parentRow
    const filteredInvoices = selectedInvoices.filter(
      inv => inv.corrected_invoice_id !== parentRow.id
    );
    // Append new selections with the parent id linked
    const updatedSelections = selectedRows.map(row => ({
      ...row,
      corrected_invoice_id: parentRow.id
    }));
    setSelectedInvoices([...filteredInvoices, ...updatedSelections]);
  };

  const nestedRowSelection = (parentRow) => ({
    selectedRowKeys: selectedInvoices
      .filter(invoice => invoice.corrected_invoice_id === parentRow.id)
      .map(invoice => invoice.id),
    onChange: (selectedRowKeys, selectedRows) => onNestedSelectChange(selectedRowKeys, selectedRows, parentRow),
  });

  const expandedRowRender = (row) => (
    <React.Fragment key={row.invoice_number}>
      <Table
        size="small"
        dataSource={row.corrective_documents.map(doc => ({
          ...doc,
          corrected_invoice_id: row.id
        }))}
        rowSelection={nestedRowSelection(row)}
        title={() => t("finance.invoices.correctiveDocument")}
        bordered={true}
        columns={invoiceTableColumns}
        showHeader={false}
        footer={false}
        rowKey={"id"}
        pagination={false}
      />
      <br></br>
    </React.Fragment>
  );

  function sendSelectedInvoices() {
    if (selectedInvoices.length > 0) {
      setDataLoading(true);

      const invoiceData = selectedInvoices.map(invoice => invoice.invoice_number);

      coreApi
        .post("invoices/send-selected", {invoice_numbers: invoiceData})
        .then((response) => {
          notification.success({ message: response.data.message });
          fetchData();
        })
        .catch((err) => notification.error({ message: err.response.data.message }))
        .finally(() => {
          setDataLoading(false);
          setShowConfirmModal(false);
        });
    } else {
      notification.error({ message: t("finance.invoices.noInvoicesSelected") });
    }
  }

  /**
   * Fetch data from API
   */
  const fetchData = useCallback(
    (sorter, pagination = null) => {
      setDataLoading(true);

      coreApi
        .get("invoices/partner-client", {
          params: {
            ...filters,
            client_id: client?.id,
            sorterOrder: sorter?.order,
            sorterKey: sorter?.columnKey,
            page_size: pagination?.pageSize || 10,
            page: pagination?.current,
          },
        })
        .then((response) => {
          let { data, ...pagination } = response.data;

          setPagination(pagination);
          setInvoices(data);
        })
        .catch((err) => notification.error({ message: err.response.data.message }))
        .finally(() => setDataLoading(false));
    },
    [client, filters]
  );

  useEffect(() => {
    fetchData();
  }, []);

  /**
   * Handle change of the table (e. g. sorting trigger)
   */
  const handleTableChange = (pagination, tableFilters, sorter) => {
    setFilters({ ...filters, pageSize: pagination?.pageSize, page: pagination?.current });
    fetchData(sorter, pagination);
  };

  /**
   * Columns of the table
   */
  const invoiceTableColumns = [
    {
      title: t("finance.balances.period"),
      key: "date_issued",
      dataIndex: "date_issued",
      sorter: true,
      render: (rowData) => {
        return moment(rowData).format("YYYY") + "/" + moment(rowData).format("MM");
      },
    },
    {
      title: t("finance.transactions.transactionNumber"),
      key: "invoice_number",
      sorter: true,
      render: (rowData) => {
        return (
          rowData?.transactions && (
            <Link style={{ borderBottom: "1px dotted" }} to={"/transactions/" + rowData?.transactions[0]?.id}>
              {rowData?.transactions[0]?.id}
            </Link>
          )
        );
      },
    },
    {
      title: t("finance.invoices.invoiceNumber"),
      key: "invoice_number",
      sorter: true,
      render: (rowData) => {
        return (
          <Link style={{ fontWeight: 500 }} to={"/invoices/" + rowData?.id}>
            {rowData?.invoice_number}
          </Link>
        );
      },
    },
    {
      title: t("finance.invoices.recipientName"),
      key: "recipient_name",
      dataIndex: "recipient_name",
      sorter: true,
    },
    {
      title: t("finance.invoices.recipientAddress"),
      key: "recipient_address",
      dataIndex: "recipient_address",
      sorter: true,
    },
    {
      title: t("finance.invoices.publisher"),
      key: "invoice_publisher_id",
      sorter: true,
      render: (rowData) => {
        return (
          rowData?.publisher && (
            <Link to={"/partners/" + rowData?.publisher?.id} style={{ borderBottom: "1px dotted" }}>
              {`${rowData?.publisher?.first_name} ${rowData?.publisher?.last_name}`}
            </Link>
          )
        );
      },
    },
    {
      title: t("finance.invoices.dateIssued"),
      key: "date_issued",
      dataIndex: "date_issued",
      sorter: true,
      render: (rowData) => {
        return moment(rowData).format("DD.MM.YYYY");
      },
    },
    {
      title: t("finance.invoices.dateDue"),
      key: "date_due",
      dataIndex: "date_due",
      sorter: true,
      render: (rowData) => {
        return moment(rowData).format("DD.MM.YYYY");
      },
    },
    {
      title: t("finance.invoices.totalAmount"),
      key: "total_amount",
      sorter: true,
      render: (rowData) => {
        return new Intl.NumberFormat("cs-CZ", {
          style: "currency",
          currency: rowData.currency_iso_4217 || "CZK",
        }).format(rowData.total_amount);
      },
    },
    {
      title: t("finance.invoices.syncDate"),
      key: "date_paind",
      dataIndex: "date_paid",
      sorter: true,
      render: (rowData) => {
        return rowData ? (
          <Tag color="green">{moment(rowData).format("DD.MM.YYYY")}</Tag>
        ) : (
          <Tag color="red">{t("finance.invoices.unpaid")}</Tag>
        );
      },
    },
    {
      title: t("finance.invoices.sentDate"),
      key: "date_sent_to_recipient",
      dataIndex: "date_sent_to_recipient",
      sorter: true,
      render: (rowData) => {
        return rowData ? (
          <Tag color="green">{moment(rowData).format("DD.MM.YYYY")}</Tag>
        ) : (
          <Tag></Tag>
        );
      },
    },
  ];

  return (
    <React.Fragment key="client_invoice_pane">
      <Row justify="end" style={{ marginBottom: 16 }}>
        <Col>
          <Button onClick={() => setShowConfirmModal(true)} disabled={!selectedInvoices.length}>{t("finance.invoices.sendSelectedInvoices")}</Button>
        </Col>
      </Row>
      <Table
        rowSelection={rowSelection}
        size="small"
        loading={dataLoading}
        dataSource={invoices}
        columns={invoiceTableColumns}
        rowKey={"id"}
        onChange={handleTableChange}
        pagination={{
          ...pagination,
          showSizeChanger: true,
          // Handle current page and page size
          // onChange: (page, pageSize) => {
          //   setFilters({ ...filters, page_size: pageSize, page: page });
          // },
        }}
        expandable={{
          expandedRowRender,
          expandIcon: ({ expanded, onExpand, record, expandable }) =>
            expandable &&
            (expanded ? (
              <DownOutlined onClick={(e) => onExpand(record, e)} /> // close
            ) : (
              <RightOutlined onClick={(e) => onExpand(record, e)} /> // open
            )),

          rowExpandable: (row) => (row?.corrective_documents.length > 0 ? true : false),
        }}
      ></Table>

      {showConfirmModal && (
        <Modal
          title={t("finance.invoices.confirmSendingInvoices")}
          open={showConfirmModal}
          onOk={sendSelectedInvoices}
          onCancel={() => setShowConfirmModal(false)}
          okButtonProps={{ loading: dataLoading }}
        >
          <p>{t("finance.invoices.sureSendingInvoices")}</p>
          <ul>
            {selectedInvoices.map(invoice => (
              <li key={invoice.id}>
                {invoice.invoice_number} - {moment(invoice.date_issued).format("MM/YYYY")}
              </li>
            ))}
          </ul>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default InvoicePane;
