import { notification, Table, Typography, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { coreApi } from "../../api/calls";
import moment from "moment";
import React, { useEffect, useState, useCallback } from "react";

/**
 * @component
 * @alias PartnerTransactionPane
 * @prop {object} transactions
 * @prop {object} partner
 */
const PartnerTransactionPane = ({ transactions: initialTransactions, partner }) => {
  const { t } = useTranslation();
  const params = useParams();

  const [partnerId, setPartnerId] = useState(partner?.id || params?.id);
  const [currentPage, setCurrentPage] = useState(1);
  const [transactions, setTransactions] = useState(initialTransactions || []);
  const [transactionsLoading, setTransactionsLoading] = useState(false);
  const [filters, setFilters] = useState({
    page: 1,
    perPage: 10,
  });

  const fetchTransactions = useCallback(() => {
    if (!partnerId) return;

    setTransactionsLoading(true);

    coreApi
      .get(`partners/${partnerId}/transactions`, { params: filters })
      .then((response) => {
        setTransactions(response.data);
      })
      .catch((error) => {
        notification.error({
          message: error?.response?.data?.message || t("error.fetchingData"),
        });
      })
      .finally(() => {
        setTransactionsLoading(false);
      });
  }, [partnerId, filters, t]);

  useEffect(() => {
    if (!initialTransactions) {
      fetchTransactions();
    }
  }, [initialTransactions, fetchTransactions]);

  const tableColumns = [
    {
      title: t("finance.transactions.transactionNumber"),
      key: "id",
      dataIndex: "id",
      render: (id) => (
        <Typography.Link href={`/transactions/${id}`}>
          {id}
        </Typography.Link>
      ),
    },
    {
      title: t("finance.transactions.accountNumberFrom"),
      key: "account_number_from",
      render: (transaction) => (
        <Typography.Text copyable key={transaction.id}>
          {transaction.account_number_from + "/" + transaction.bank_code_from}
        </Typography.Text>
      ),
    },
    {
      title: t("finance.transactions.accountNameFrom"),
      key: "account_name_from",
      dataIndex: "account_name_from",
      render: (value) => value || "-",
    },
    {
      title: t("finance.transactions.variableSymbol"),
      key: "variable_symbol",
      dataIndex: "variable_symbol",
      render: (variableSymbol) => (
        <Typography.Text copyable key={variableSymbol}>
          {variableSymbol}
        </Typography.Text>
      ),
    },
    {
      title: t("finance.transactions.amount"),
      key: "amount",
      sorter: true,
      render: (rowData) => {
        return new Intl.NumberFormat("cs-CZ", {
          style: "currency",
          currency: rowData.currency_iso_4217 || "CZK",
        }).format(rowData.pivot?.amount || rowData.amount);
      },
    },
    {
      title: t("finance.transactions.paymentDate"),
      key: "date_transaction",
      dataIndex: "date_transaction",
      sorter: true,
      render: (rowData) => {
        return moment(rowData).format("L");
      },
    },
  ];

  return (
    <React.Fragment>
      <Spin spinning={transactionsLoading}>
        <Table
          key={"partner-transaction-table"}
          size="small"
          dataSource={transactions?.data || transactions}
          columns={tableColumns}
          pagination={{
            current: transactions?.current_page || currentPage,
            pageSize: transactions?.per_page || filters.perPage,
            total: transactions?.total || 0,
            showSizeChanger: true,
            onChange: (page, pageSize) => {
              setCurrentPage(page);
              setFilters({ ...filters, page, perPage: pageSize });
            },
          }}
          rowKey={"id"}
        ></Table>
      </Spin>
    </React.Fragment>
  );
};

export default PartnerTransactionPane;
