import {Button, Col, Form, Input, Modal, notification, Row, Table} from "antd";
import React, {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router";
import {coreApi} from "../api/calls";
import parse from "html-react-parser";
import moment from "moment/moment";
import BuildingContactFilterPanel from "./Buildings/BuildingContactFilterPanel.jsx";
import BuildingAddCommunication from "./Buildings/BuildingAddCommunication";
import {CheckOutlined, CloseOutlined, EditOutlined} from "@ant-design/icons";

/**
 * Page with all sent emails
 * @component
 * @alias CommunicationPane
 * @returns <React.Fragment /> with content
 */
const CommunicationPane = ({ currentTabKey = '' }) => {
  // Translation
  const { t } = useTranslation();

  // Id from URL
  const { id } = useParams();

  // States
  const [emails, setEmails] = useState([]);
  const [email, setEmail] = useState({});
  const [pagination, setPagination] = useState({});
  const [params, setParams] = useState({});
  const [loading, setDataLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingRowKey, setEditingRowKey] = useState(null);
  const [rawValue, setRawValue] = useState(null);
  const [filters, setFilters] = useState({cases: ['phone', 'email', 'action']});
  /**
   * Handle street onchange filter with lodash
   */


  const fetchEmails = useCallback(() => {
    setDataLoading(true);

    const mergedParams = {
      ...params,
      ...filters,
    };

    coreApi
      .get(`/emails/buildings/` + id, { params: mergedParams })
      .then((response) => {
        let { data, ...pagination } = response.data;

        setEmails(data);
        setPagination(pagination);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setDataLoading(false)
      });
  }, [params, filters]);

  const updateEmail = (record) => {
    setDataLoading(true);
    coreApi
      .put("/emails/" + record.id, record)
      .catch((error) => {
        notification.error({ message: error?.response?.data?.message });
      }).finally(() => {
      setDataLoading(false)
    });
  };

  useEffect(() => {
    fetchEmails();
  }, [fetchEmails]);

  useEffect(() => {
    if (currentTabKey === '#communication') {
      fetchEmails();
    }
    return () => {}
  }, [currentTabKey]);

  const handleSave = (record) => {
    if (record.subject !== rawValue) {
      record.subject = rawValue;
      updateEmail(record);
    }
    handleCancel();
  };

  const handleEdit = (record) => {
    setEditingRowKey(record.id)
    setRawValue(record.subject);
  };

  const isEditing = (record) => record.id === editingRowKey;

  const handleCancel = () => {
    setEditingRowKey(null);
    setRawValue(null);
  };

  function cleanText(input) {
    return input
      .replace(/(@media[\s\S]*?\{[\s\S]*?\})/g, "")
      .replace(/(\.\w+[\s\S]*?\{[\s\S]*?\})/g, "")
      .replace(/@[\s\S]*?\}/g, "")
      .replace(/^[\s\}]+/g, "")
      .replace(/\n\s*\n+/g, "\n")
      .trim();
  }

  // Columns of table
  const columns = [
    {
      title: t("emails.date"),
      key: "created_at",
      dataIndex: "created_at",
      width: "8%",
      render: (rowData) => {
        return moment(rowData).format("DD") + "." + moment(rowData).format("MM") + "." + moment(rowData).format("YYYY");
      },
    },
    {
      title: t("emails.communication"),
      key: "case",
      dataIndex: "case",
      width: "10%",
      render: (rowData) => {
        const translationKey = "emails.communication_cases." + rowData;
        const translatedText = t(translationKey);
        return translatedText !== translationKey ? translatedText : t("emails.communication_cases.schedule");
      },
    },
    {
      title: t("emails.communication_type"),
      key: "type",
      dataIndex: "type",
      width: "10%",
      render: (rowData) => {
        return t("emails.types." + rowData);
      },
    },
    {
      title: t("emails.with"),
      key: "to_full",
      dataIndex: "to_full",
      width: "10%",
    },
    {
      title: t("invoices.manager"),
      key: "from_full",
      width: "10%",
      render: (rowData) => {
        return rowData.from_full ?? t("common.system");
      },
    },
    {
      title: t("emails.description"),
      key: "subject",
      dataIndex: "subject",
      render: (rowData, record) => {
        const plainText = record.body_html.replace(/<\/?[^>]+(>|$)/g, '');
        const displayText = cleanText(plainText.length ? plainText : rowData);

        return isEditing(record) ? (
          <div
            contentEditable
            suppressContentEditableWarning
            id={record.id}
            onInput={(e) => setRawValue(e.target.textContent)}
            style={{
              cursor: "text",
              border: "1px solid #1890ff",
            }}
          >
            {displayText}
          </div>
        ) : (
          displayText
        );
      },
    },
    {
      key: "action",
      width: "3%",
      render: (_, record) => {
        return isEditing(record)
          ? <div>
              <Button icon={<CheckOutlined />} onClick={() => handleSave(record)}></Button>
              <Button danger icon={<CloseOutlined />} onClick={handleCancel}></Button>
            </div>
          : <Button icon={<EditOutlined />} onClick={() => handleEdit(record)}></Button>;
      },
    }
  ];

  return (
    <React.Fragment>
      <Row>
        <Col span={24} gutter={[16, 16]} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
          <BuildingContactFilterPanel
            filters={filters}
            setFilters={setFilters}
            fetchEmails={fetchEmails}
            t={t}
          />
          <BuildingAddCommunication id={id} onUpdate={fetchEmails}></BuildingAddCommunication>
        </Col>
      </Row>
      <Table
        id="communication_table"
        size={"small"}
        loading={loading}
        dataSource={emails}
        pagination={{
          ...pagination,
          showSizeChanger: true,
          onChange: (e, perPage) => {
            setParams({ ...params, page: e, perPage: perPage });
          },
          showTotal: (total, range) => (
            <p>
              {t("common.paginationPartOne")} <strong>{range[0]}</strong>-<strong>{range[1]}</strong>
              {" " + t("common.paginationPartTwo") + " "}
              <strong>{total}</strong> {t("common.paginationPartThree")}
            </p>
          ),
        }}
        columns={columns}
        onChange={(e) => {
          console.log(e);
        }}
      />
        
      <Modal
        width={1000}
        open={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
      >
        {email.body_html?.length === 0 ? (
          email.subject
        ) : (
          email.body_html && parse(email.body_html)
        )}
      </Modal>
    </React.Fragment>
  );
};

export default CommunicationPane;
