import { ExclamationCircleOutlined, SendOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Form, Modal, notification, Row, Space, Table, Tabs, Tag } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";
import PartnerBalanceCitySelector from "./PartnerBalanceCitySelector";
import { useParams } from "react-router-dom";
import PartnerPaymentRequest from "./PartnerPaymentRequest";
import { Link } from "react-router-dom";
import SinglePartnerPaymentPane from "./SinglePartnerPaymentPane";

const PartnerBalancePane = ({ partner, ...props }) => {
  const { t } = useTranslation();
  const [tabKey, setTabKey] = useState("total");

  const [balances, setBalances] = useState([]);

  const [idCityBranch, setIdCityBranch] = useState(null);

  const params = useParams();
  const currency = partner?.city_branches[0]?.country_branch?.iso_4217_currency_code || "CZK";

  const [dateFrom, setDateFrom] = useState(moment().subtract(3, "months").startOf("month"));
  const [dateTo, setDateTo] = useState(moment().subtract(1, "months").endOf("month"));

  const [balanceLoading, setBalanceLoading] = useState(false);

  const [variableSymbol, setVariableSymbol] = useState(null);

  const updateXmlAndSymbol = (xmlData) => {
    const regexVarSymbol = xmlData && xmlData.match(/<vs>(\d+)<\/vs>/);
    const variableSymbol = (regexVarSymbol && regexVarSymbol.length > 1 && regexVarSymbol[1]) ? regexVarSymbol[1] : null;

    setVariableSymbol(variableSymbol);
  };

  const fetchData = useCallback(() => {
    setBalanceLoading(true);

    coreApi
      .get("partners/" + params?.id + "/balances-by-city", {
        params: {
          date_from: dateFrom.startOf("month").format("YYYY-MM-DD"),
          date_to: dateTo.endOf("month").format("YYYY-MM-DD"),
          city_branch_id: idCityBranch,
        },
      })
      .then((response) => {

        const transformedData = {
          partner: response.data.partner || {},
          partnerByCity: Object.values(response.data.partnerByCity || {}),
          workers: response.data.workers || {},
          workersByCity: Object.values(response.data.workersByCity || {}),
          total: response.data.total || {},
          totalByCity: Object.values(response.data.totalByCity || {})
        };

        setBalances(transformedData);

      })
      .catch((error) => {
        notification.error({
          message: error?.response?.data?.message,
        });
      })
      .finally(() => {
        setBalanceLoading(false);
      });
  }, [partner, idCityBranch, dateFrom, dateTo, params]);

  const onPaymentSuccess = useCallback(() => {
    fetchData();
  }, [fetchData]);


  const sendBalanceEmail = useCallback((specificDateFrom, specificDateTo, specificCityBranchId) => {
    setBalanceLoading(true);

    coreApi
      .post("payments/send-partner-balance-mail", {
        date_to: specificDateTo,
        date_from: specificDateFrom,
        partner_id: params?.id,
        city_branch_id: specificCityBranchId,
      })
      .then((response) => {
        notification.success({
          message: response.data?.message || t("finance.balances.emailSentSuccess"),
        });
      })
      .catch((error) => {
        notification.error({
          message: error?.response?.data?.message || t("finance.balances.emailSentError"),
        });
      })
      .finally(() => {
        setBalanceLoading(false);
      });
  }, [params, t]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const columns = [
    {
      title: t("finance.invoices.supplierCity"),
      key: "city_branch",
      render: (record) => record?.city_branch?.name || "—",
    },
    {
      title: t("finance.balances.period"),
      dataIndex: "period",
      key: "period",
    },
    {
      title: t("finance.invoices.partnerClientInvoices"),
      dataIndex: "partner_client_sum",
      key: "partner_client_sum",
      render: (value) =>
        new Intl.NumberFormat("cs-CZ", {
          style: "currency",
          currency: currency,
        }).format(value || 0),
    },
    {
      title: t("finance.invoices.partnerPartnerInvoicesPublished"),
      dataIndex: "partner_partner_published_sum",
      key: "partner_partner_published_sum",
      render: (value) =>
        new Intl.NumberFormat("cs-CZ", {
          style: "currency",
          currency: currency,
        }).format(value || 0),
    },
    {
      title: t("finance.invoices.partnerAnnaInvoices"),
      dataIndex: "partner_anna_sum",
      key: "partner_anna_sum",
      render: (value) =>
        new Intl.NumberFormat("cs-CZ", {
          style: "currency",
          currency: currency,
        }).format(value || 0),
    },
  {
      title: t("finance.invoices.annaPartnerInvoices"),
      dataIndex: "anna_partner_sum",
      key: "anna_partner_sum",
      render: (value) =>
        new Intl.NumberFormat("cs-CZ", {
          style: "currency",
          currency: currency,
        }).format(value || 0),
    },
    {
      title: t("finance.invoices.partnerPartnerInvoicesReceived"),
      dataIndex: "partner_partner_received_sum",
      key: "partner_partner_received_sum",
      render: (value) =>
        new Intl.NumberFormat("cs-CZ", {
          style: "currency",
          currency: currency,
        }).format(value || 0),
    },
    {
      title: t("finance.invoices.correctiveDocuments"),
      dataIndex: "corrective_document_sum",
      key: "corrective_document_sum",
      render: (value) =>
        new Intl.NumberFormat("cs-CZ", {
          style: "currency",
          currency: currency,
        }).format(value || 0),
    },
    {
      title: t("finance.balances.reward"),
      dataIndex: "total_sum",
      key: "total_sum",
      render: (value) =>
        new Intl.NumberFormat("cs-CZ", {
          style: "currency",
          currency: currency,
        }).format(value || 0),
    }];

  const additionalColumns = [
    {
      title: t("finance.balances.rewardPaid"),
      dataIndex: "transaction",
      key: "transaction",
      render: (value) => {
        return value ? (new Intl.NumberFormat("cs-CZ", {
          style: "currency",
          currency: value?.currency_iso_4217 || currency,
        }).format(value.amount || 0)) : 0
      }
    },
    {
      title: t("finance.balances.difference"),
      key: "difference",
      render: (record) => {
        const totalSum = record?.total_sum || 0;
        const transactionAmount = record?.transaction?.amount || 0;
        const difference = totalSum - transactionAmount;

        return new Intl.NumberFormat("cs-CZ", {
          style: "currency",
          currency: currency,
        }).format(difference || 0);
      },
    },
    {
      title: t("finance.transactions.paymentDate"),
      dataIndex: "transaction",
      key: "transaction",
      render: (record) => record?.date_transaction ? moment(record?.date_transaction)?.format("DD.MM.YYYY") : '-'
    }];

  const actionColumn = {
    title: t("common.actions"),
    key: "actions",
    width: 250,
    render: (_, record) => (
      <Space direction="horizontal" wrap>
        <SinglePartnerPaymentPane
          partner={partner}
          params={params}
          dateFrom={moment(record.created_at).startOf("month").format("YYYY-MM-DD")}
          dateTo={moment(record.created_at).endOf("month").format("YYYY-MM-DD")}
          idCityBranch={record.city_branch_id}
          transaction_date={record.period ? moment(record.period, "MM/YYYY").date(16).format("YYYY-MM-DD") : null}
          onXmlUpdate={updateXmlAndSymbol}
        />

        {/*/!* Partner Payment Request or Sent At Tag *!/*/}
        {partner?.company_number &&
          (!record?.sent_at ? (
            <PartnerPaymentRequest
              partner={partner}
              dateFrom={moment(record.created_at).startOf("month").format("YYYY-MM-DD")}
              dateTo={moment(record.created_at).endOf("month").format("YYYY-MM-DD")}
              total={record?.total_sum}
              variableSymbol={variableSymbol}
              idCityBranch={record?.city_branch_id || null}
              transaction_date={record.period ? moment(record.period, "MM/YYYY").date(1).format("YYYY-MM-DD") : null}
              onPaymentSuccess={onPaymentSuccess}
              showButtonText={false}
            />
          ) : (
            <Tag color="blue">
              {t("finance.transactions.sentAt")}: {moment(record?.sent_at)?.format("DD.MM.YYYY HH:mm")}
            </Tag>
          ))
        }

        {/* Transaction Link */}
        {record?.transaction_id && (
          <Link to={`/transactions/${record.transaction_id}`} target={"_blank"}>
            <Button>{t("finance.transactions.showTransaction")}</Button>
          </Link>
        )}

        {/*Send Balance Email Button*/}
        {(record?.total_sum ?? 0) > 0 && (
          <Button
            type="primary"
            icon={<SendOutlined />}
            title={t("partners.sendBalanceEmail")}
            onClick={() => {
              Modal.confirm({
                title: t("finance.balances.sureSendingBalance"),
                icon: <ExclamationCircleOutlined />,
                okButtonProps: { danger: true, loading: balanceLoading },
                onOk: () => sendBalanceEmail(
                  moment(record.created_at).startOf("month").format("YYYY-MM-DD"),
                  moment(record.created_at).endOf("month").format("YYYY-MM-DD"),
                  record?.city_branch_id || null
                ),
              });
            }}
          >
            {t("emails.communication_cases.email")}
          </Button>
        )}
      </Space>
    ),
  };

  const totalFields = [
    { key: "partner_client_sum" },
    { key: "partner_partner_published_sum" },
    { key: "partner_anna_sum" },
    { key: "anna_partner_sum" },
    { key: "partner_partner_received_sum" },
    { key: "corrective_document_sum" },
    { key: "balance_sum" },
  ];

  return (
    <React.Fragment>
      <Row gutter={[16, 0]}>
        <Col>
          <Form.Item label={t("finance.balances.period")}>
            <DatePicker.RangePicker
              defaultValue={[dateFrom, dateTo]}
              picker={"month"}
              format={"MMMM Y"}
              onChange={(selectedDates) => {
                setDateFrom(selectedDates[0]);
                setDateTo(selectedDates[1]);
              }}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <PartnerBalanceCitySelector
            name={"city_branch_id"}
            label={t("expansion.cities")}
            required={false}
            showSearch={true}
            setValue={setIdCityBranch}
            idPartner={params?.id}
          />
        </Col>
      </Row>

      {balances && <Row gutter={[16, 0]}>
        <Tabs activeKey={tabKey} onChange={setTabKey} style={{ marginTop: 16 }}>
          <Tabs.TabPane tab={t("finance.invoices.totalBalance")} key="total">
            <Table
              columns={[...columns, ...additionalColumns, actionColumn]}
              dataSource={balances.totalByCity || []}
              loading={balanceLoading}
              rowKey={(record) => `${record.city_branch_id}-${record.period}`}
              pagination={{ pageSize: 100 }}
              summary={() => {
                if (!(balances.totalByCity || [])?.length) {
                  return null;
                }
                return (
                  <Table.Summary.Row style={{ fontWeight: "bold", backgroundColor: "#DEDEDEFF" }}>
                    <Table.Summary.Cell colSpan={2}>{t("common.total")}</Table.Summary.Cell>
                    {totalFields.map(({ key }) => (
                      <Table.Summary.Cell key={key}>
                        {new Intl.NumberFormat("cs-CZ", {
                          style: "currency",
                          currency: currency,
                        }).format(balances?.total?.[key] || 0)}
                      </Table.Summary.Cell>
                    ))}

                    <Table.Summary.Cell>
                      {new Intl.NumberFormat("cs-CZ", {
                        style: "currency",
                        currency: currency,
                      }).format(
                        (balances?.totalByCity || []).reduce((sum, record) => sum + (Number(record?.transaction?.amount) || 0), 0)
                      )}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      {new Intl.NumberFormat("cs-CZ", {
                        style: "currency",
                        currency: currency,
                      }).format(
                        (Number(balances?.total?.total_sum) || 0) -
                        (balances?.totalByCity || []).reduce((sum, record) => sum + (Number(record?.transaction?.amount) || 0), 0)
                      )}
                    </Table.Summary.Cell>

                    <Table.Summary.Cell />
                    <Table.Summary.Cell />
                  </Table.Summary.Row>
                );
              }}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab={t("finance.invoices.partnerBalance")} key="contractor">
            <Table
              columns={columns}
              dataSource={balances.partnerByCity || []}
              loading={balanceLoading}
              rowKey={(record) => `${record.city_branch_id}-${record.period}`}
              pagination={{ pageSize: 100 }}
              summary={() => {
                if (!(balances.partnerByCity || [])?.length) {
                  return null;
                }
                return (
                  <Table.Summary.Row style={{ fontWeight: "bold", backgroundColor: "#DEDEDEFF" }}>
                    <Table.Summary.Cell colSpan={2}>{t("common.total")}</Table.Summary.Cell>
                    {totalFields.map(({ key }) => (
                      <Table.Summary.Cell key={key}>
                        {new Intl.NumberFormat("cs-CZ", {
                          style: "currency",
                          currency: currency,
                        }).format(balances?.partner?.[key] || 0)}
                      </Table.Summary.Cell>
                    ))}
                  </Table.Summary.Row>
                );
              }}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab={t("finance.invoices.workersBalance")} key="worker">
            <Table
              columns={columns}
              dataSource={balances.workersByCity || []}
              loading={balanceLoading}
              rowKey={(record) => `${record.city_branch_id}-${record.period}`}
              pagination={{ pageSize: 100 }}
              summary={() => {
                if (!(balances.workersByCity || [])?.length) {
                  return null;
                }
                return (
                  <Table.Summary.Row style={{ fontWeight: "bold", backgroundColor: "#DEDEDEFF" }}>
                    <Table.Summary.Cell colSpan={2}>{t("common.total")}</Table.Summary.Cell>
                    {totalFields.map(({ key }) => (
                      <Table.Summary.Cell key={key}>
                        {new Intl.NumberFormat("cs-CZ", {
                          style: "currency",
                          currency: currency,
                        }).format(balances?.workers?.[key] || 0)}
                      </Table.Summary.Cell>
                    ))}
                  </Table.Summary.Row>
                );
              }}
            />
          </Tabs.TabPane>
        </Tabs>
      </Row>}

    </React.Fragment>
  );
};

export default PartnerBalancePane;
