import { SendOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Modal, notification, Row, DatePicker } from "antd";
import React, { useState, useEffect } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";

const PartnerPaymentRequest = ({ partner, total, dateFrom, dateTo, variableSymbol, idCityBranch, onPaymentSuccess, showButtonText = true, transaction_date = null }) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const [isOpen, setIsOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const [dateDue, setDateDue] = useState(moment().date(16));

  const [selectedCity, setSelectedCity] = useState(null);
  const [localVariableSymbol, setLocalVariableSymbol] = useState(variableSymbol);

  const fetchPartnerPaymentXml = async () => {
    setLoading(true);
    try {
      const res = await coreApi.post("payments/single-partner-payment", {
        transaction_date: transaction_date,
        date_to: dateTo,
        date_from: dateFrom,
        partner_id: partner?.id,
        city_branch_id: idCityBranch,
      });
      const regexVarSymbol = res.data.xml.match(/<vs>(\d+)<\/vs>/);
      const symbol = regexVarSymbol?.[1] || null;
      setLocalVariableSymbol(symbol);
      form.setFieldsValue({ variable_symbol: symbol });
    } catch (err) {
      notification.error({
        message: t("error.title"),
        description: err.response?.data || t("error.generic"),
      });
    } finally {
      setLoading(false);
    }
  };

  const normalizeDiacritics = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  const message = normalizeDiacritics(
    `${moment(dateTo)?.format("YYYY")}${moment(dateTo)?.format("MM")} ${selectedCity ? selectedCity : ""} ${t(
      "finance.moneyReturn.cleaningServices"
    )} ${partner?.company_name}`
  );

  const getBankAccount = () => {
    if (partner?.currency_iso_4217 === "EUR" && partner?.account_iban) {
      return partner.account_iban;
    }

    if (partner?.account_number) {
      return partner?.account_prefix
        ? `${partner.account_prefix}-${partner.account_number}/${partner.account_bank_code}`
        : `${partner.account_number}/${partner.account_bank_code}`;
    }

    return "";
  };

  const bankAccount = getBankAccount();

  const handleOk = () => {
    if (!bankAccount) {
      notification.error({ message: t("finance.moneyReturn.accountDataNotFilled")});
      return;
    }
    setLoading(true);
    let payload = {
      transaction_date: moment(dateDue)?.format("YYYY-MM-DD"),
      date_from: moment(dateFrom)?.format("YYYY-MM-DD"),
      date_to: moment(dateTo)?.format("YYYY-MM-DD"),
      partner_id: partner.id,
      city_branch_id: idCityBranch,
    };

    coreApi
      .post("payments/save-single-partner-request", { ...payload })
      .then((res) => {
        notification.success({ message: res.data.message });
        setIsOpen(false);
        onPaymentSuccess();
      })
      .catch((err) => {
        notification.error({
          message: err?.response?.data?.message,
          description: err?.response?.data?.error,
        });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    form.setFieldsValue({
      amount: total,
    });
  }, [total, form]);

  useEffect(() => {
    idCityBranch &&
      coreApi
        .get("/cities/name/" + idCityBranch)
        .then((response) => {
          setSelectedCity(response.data.name);
        })
        .catch((error) => {
          notification.error({
            message: error.response.data.message,
          });
        });
  }, [idCityBranch]);

  const handleOpen = async () => {
    if (!variableSymbol) {
      await fetchPartnerPaymentXml();
    }
    setIsOpen(true);
  };

  useEffect(() => {
    setLocalVariableSymbol(variableSymbol);
  }, [variableSymbol]);

  return (
    <React.Fragment>
      <Button
        icon={<SendOutlined />}
        onClick={handleOpen}
        title={t("finance.moneyReturn.sendPaymentForApproval")}>
        {showButtonText ? t("finance.moneyReturn.sendPaymentForApproval") : ""}
      </Button>
      <Modal
        key={partner?.id}
        open={isOpen}
        onCancel={() => setIsOpen(!isOpen)}
        title={t("buildings.rewardPrescription")}
        okText={t("finance.moneyReturn.sendPaymentForApproval")}
        onOk={handleOk}
        okButtonProps={{ loading: loading, disabled: (!total) }}
      >
        <Form form={form} layout="inline">
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item name="account_to" label={t("finance.accounts.accountTo")} initialValue={bankAccount}>
                <Input disabled={true} bordered={false}></Input>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                name="variable_symbol"
                label={t("finance.transactions.variableSymbol")}
                initialValue={localVariableSymbol}
              >
                <Input disabled={true} bordered={false}></Input>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                name="amount"
                label={t("finance.moneyReturn.amount")}
                initialValue={Math.abs(total).toFixed(2)}
              >
                <Input disabled={true} bordered={false}></Input>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item name="message" label={t("finance.transactions.message")} disabled={true}>
                {message}
              </Form.Item>
            </Col>

            <Col span={16}>
              <Form.Item label={t("finance.moneyReturn.date")} name={"transaction_date"} initialValue={dateDue}>
                <DatePicker
                  allowClear={false}
                  allowEmpty={false}
                  onChange={(value) => setDateDue(value)}
                  style={{ width: "100%" }}
                  disabledDate={(current) =>
                    current.format("YYYY-MM-DD") < moment().format("YYYY-MM-DD")
                  }
                ></DatePicker>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default PartnerPaymentRequest;
