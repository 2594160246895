import React, { useState, useEffect } from "react";
import { Form, Select, DatePicker, Button, Input, Row, Col, notification, Tooltip } from "antd";
import PartnerSelect from "./../../Partners/PartnerSelect";
import { coreApi } from "../../../api/calls";
import { useTranslation } from "react-i18next";
import moment from "moment/moment";
import { RRule } from "rrule";
import { getDays } from '../../helpers/rrule';

const { Option } = Select;
const { TextArea } = Input;

const EditManyForm = ({ items, onSave, onCancel }) => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [actions, setActions] = useState([]);
  const days = ["MO", "TU", "WE", "TH", "FR", "SA", "SU"];

  useEffect(() => {
    let isMounted = true;

    fetchActions();

    if (items && items.length > 0 && isMounted) {
      const initialValues = {
        items: items.map((item) => ({
          id: item.id,
          action_id: item.action_id,
          partner_id: item?.partner_id || null,
          substitute_partner_id: item?.substitute_partner_id || null,
          substitute_rrule: item?.substitute_rrule ? getDays(item.substitute_rrule) : [],
          action_period: item.action_week_from ? [moment(item.action_week_from), moment(item.action_week_to)] : [],
          notes: item?.notes || null,
        })),
      };
      form.setFieldsValue(initialValues);
    }

    return () => {
      isMounted = false;
      form.resetFields();
    };
  }, [items]);

  const fetchActions = async () => {
    try {
      const response = await coreApi.get("/building-unit-setup-changes/actions/list-actions");
      setActions(response.data);
    } catch (error) {
      notification.error({ message: t("buildingSetupChanges.messages.failedLoadData") });
    }
  };

  const handleSubmit = async (values) => {
    let isMounted = true;
    setLoading(true);

    const itemsToSave = values.items.map((item) => {
      const rruleString = item.substitute_rrule && item.substitute_rrule.length > 0
        ? new RRule({
          freq: RRule.WEEKLY,
          byweekday: item.substitute_rrule.map((day) => RRule[day])
        }).toString()
        : null;

      return {
        ...item,
        substitute_rrule: rruleString,
        action_id: item.action_id || null,
        partner_id: item.partner_id || null,
        substitute_partner_id: item.substitute_partner_id || null,
        action_period: item.action_period || null,
        notes: item.notes || null
      };
    });

    try {
      await Promise.all(
        itemsToSave.map((payload) => coreApi.put(`/building-unit-setup-changes/${payload.id}`, payload))
      );
      if (isMounted) {
        notification.success({ message: t("common.saveSuccess") });
        onSave();
        form.resetFields();
      }
    } catch (error) {
      if (isMounted) {
        notification.error({ message: t("common.saveFailed"), description: t("Some items failed to save.") });
      }
    } finally {
      if (isMounted) {
        setLoading(false);
      }
    }

    return () => {
      isMounted = false;
    };
  };

  const handleRruleChange = (index, newRrule) => {
    form.setFields([
      {
        name: ['items', index, 'substitute_rrule'],
        value: newRrule,
      },
    ]);
  };

  return (
    <Form form={form} onFinish={handleSubmit} layout="vertical">
      <Row gutter={16}>
        <Col span={3}>
          <strong>{t("buildingSetupChanges.buildingUnit")}</strong>
        </Col>
        <Col span={3}>
          <strong>{t("buildingSetupChanges.action")}</strong>
        </Col>
        <Col span={5}>
          <strong>{t("buildingSetupChanges.actionWeek")}</strong>
        </Col>
        <Col span={3}>
          <strong>{t("common.partner")}</strong>
        </Col>
        <Col span={3}>
          <strong>{t("common.substitutePartner")}</strong>
        </Col>
        <Col span={3}>
          <strong>{t("common.day")}</strong>
        </Col>
        <Col span={4}>
          <strong>{t("common.notes")}</strong>
        </Col>
      </Row>

      <Form.List name="items">
        {(fields) => (
          <>
            {fields.map((field, index) => {
              const item = items[index];
              return (
                <Row gutter={16} key={field.name}>
                  <Form.Item
                    name={[field.name, "id"]}
                    initialValue={item.id}
                    style={{ display: 'none' }}
                  >
                    <Input type="hidden" />
                  </Form.Item>
                <Col span={3}>
                    <Form.Item>
                      <Tooltip title={`${item.building_unit?.street} ${item.building_unit?.house_number}`}>
                        {item.building_unit?.street} {item.building_unit?.house_number}
                      </Tooltip>
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item name={[field.name, "action_id"]}>
                      <Select placeholder={t("buildingSetupChanges.selectAction")} allowClear>
                        {actions.map((action) => {
                          const translation = action.translations.find(
                            (t) => t.language_ietf === i18n.language
                          ) || action.translations[0];
                          return (
                            <Option key={action.id} value={action.id}>
                              {translation?.tag_name}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={5}>
                    <Form.Item name={[field.name, "action_period"]}>
                      <DatePicker.RangePicker allowEmpty={[false, true]} />
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <PartnerSelect
                      name={[field.name, "partner_id"]}
                      value={form.getFieldValue([field.name, "partner_id"])}
                    />
                  </Col>
                  <Col span={3}>
                    <PartnerSelect
                      name={[field.name, "substitute_partner_id"]}
                      value={form.getFieldValue([field.name, "substitute_partner_id"])}
                    />
                  </Col>
                  <Col span={3}>
                    <Form.Item
                      name={[field.name, "substitute_rrule"]}
                    >
                      <Select
                        mode={"multiple"}
                        allowClear
                        placeholder={t("rrules.weekday")}
                        onChange={(values) => handleRruleChange(index, values)}
                      >
                        {days.map((day) => (
                          <Option key={day} value={day}>
                            {t("rrules.byweekday." + day)}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item name={[field.name, "notes"]}>
                      <TextArea rows={1} maxLength={250} showCount />
                    </Form.Item>
                  </Col>
                </Row>
              );
            })}
          </>
        )}
      </Form.List>

      <Row gutter={16}>
        <Col span={24}>
          <Form.Item style={{ textAlign: "right" }}>
            <Button type="ghost" onClick={onCancel} style={{ marginRight: 8 }}>
              {t("common.cancel")}
            </Button>
            <Button type="primary" htmlType="submit" loading={loading} disabled={loading}>
              {t("common.save")}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default EditManyForm;
