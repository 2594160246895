import React from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "antd";

/**
 * Building Unit Link - turns building unit street and house number to clickable link that leads to a building unit profile
 * @component
 * @alias BuildingUnitLink
 * @property {object} buildingUnit - details of buildingUnit
 * @returns <Link />
 */
const BuildingUnitLink = ({ buildingUnit }) => {
  return (
    <Tooltip title={buildingUnit?.street + " " + buildingUnit?.house_number}>
      <Link
        to={"/buildings/" + buildingUnit?.building_id + "/unit/" + buildingUnit?.id}
        style={{ borderBottom: "1px dotted" }}
        target={"_blank"}
      >
        {buildingUnit?.street + " " + buildingUnit?.house_number}
      </Link>
    </Tooltip>
  );
};

export default BuildingUnitLink;
